import "@hotwired/turbo";
import { Popover } from "bootstrap";
import * as ActiveStorage from "@rails/activestorage";
import "./controllers"

ActiveStorage.start();

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

Popover.Default.allowList.a.push(/^data-[\w-]*$/i);

addEventListener("turbo:load", () => {
  document
    .querySelectorAll('[data-bs-toggle="popover"]')
    .forEach((element) => new Popover(element));
});
