import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["button"];

  static values = {
    checkSelector: String
  };

  static classes = ["button", "check", "transition"];

  #LOCAL_STORAGE_KEY = "theme";
  #THEMES = { auto: "auto", dark: "dark", light: "light" };

  connect() {
    const theme = this.#getValidatedTheme(this.#storedTheme);
    this.#theme = theme;
    this.#updateButtons(theme);
  }

  switchTheme({ params }) {
    const theme = this.#getValidatedTheme(params.theme);
    this.#storedTheme = theme;
    this.#theme = theme;
    this.#updateButtons(theme);
  }

  #getValidatedTheme(theme) {
    return Object.values(this.#THEMES).includes(theme) ? theme : this.#THEMES.auto;
  }

  #updateButtons(theme) {
    this.buttonTargets.forEach((target) => {
      target.classList.remove(this.buttonClass);
      target.querySelector(this.checkSelectorValue).classList.add(this.checkClass);
    });
    const activeButton = this.buttonTargets.find((target) => target.dataset.themeSwitcherThemeParam === theme);
    activeButton.classList.add(this.buttonClass);
    activeButton.querySelector(this.checkSelectorValue).classList.remove(this.checkClass);
  }

  get #defaultTheme() {
    if (window.matchMedia(`(prefers-color-scheme: ${this.#THEMES.dark})`).matches) {
      return this.#THEMES.dark;
    }
    return this.#THEMES.light;
  }

  get #storedTheme() {
    return localStorage.getItem(this.#LOCAL_STORAGE_KEY);
  }

  set #storedTheme(theme) {
    localStorage.setItem(this.#LOCAL_STORAGE_KEY, theme);
  }

  set #theme(theme) {
    if (theme === this.#THEMES.auto) theme = this.#defaultTheme;
    const element = document.documentElement;
    element.classList.add(this.transitionClass);
    element.dataset.bsTheme = theme;
    requestAnimationFrame(() => element.classList.remove(this.transitionClass));
  }
}
