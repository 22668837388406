import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    document.addEventListener("turbo:render", this.#restore.bind(this), { once: true });
  }

  #restore() {
    this.element.selectionStart = this.element.value.length;
  }
}
