import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  #MODAL_SELECTOR = ".modal";
  #FRAME_SELECTOR = "turbo-frame";

  hide() {
    this.#modal.hide();
  }

  show() {
    this.#modal.show();
  }

  reset() {
    this.#modalFrame.removeAttribute("src");
    this.#modalElement.remove();
  }

  get #modal() {
    return Modal.getOrCreateInstance(this.#modalElement);
  }

  get #modalElement() {
    return this.element.querySelector(this.#MODAL_SELECTOR);
  }

  get #modalFrame() {
    return this.element.closest(this.#FRAME_SELECTOR);
  }
}
