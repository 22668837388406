import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.debouncedSubmit = this.#debounce(this.#submit.bind(this), 250);
  }

  #submit() {
    this.element.requestSubmit();
  }

  #debounce(callback, wait) {
    let timeout = null;
    return () => {
      if (timeout) clearTimeout(timeout);
      timeout = setTimeout(callback, wait);
    };
  }
}
